import {APP_INITIALIZER, NgModule} from '@angular/core';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {environment} from 'src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CheckUserAuthenticationComponent} from './check-user-authentication.component';
import {CheckUserRolesComponent} from './check-user-roles.component';
import {CoreModule} from './core/core.module';
import {UserAuthenticationCheckGuard} from './shared/guards/user-authentication-check.guard';
import {UserProfileCheckGuard} from './shared/guards/user-profile-check.guard';
import {AuthenticationService} from './shared/services/authentication.service';
import {ConfigService} from './shared/services/config.service';
import {SharedModule} from './shared/shared-module';
import {UnauthorizedModule} from './unauthorized/unauthorized.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoAuthenticationAccessComponent} from './no-authentication-access.component';
import {ServiceManagerComponent} from './core/service-manager/service-manager.component';
import {DsiManagementListComponent} from './core/service-manager/dsi-management-list/dsi-management-list.component';
import {
  ApplicationManagementListComponent
} from './core/service-manager/application-management-list/application-management-list.component';
import {MatConfirmDialogComponent} from './shared/mat-confirm-dialog/mat-confirm-dialog.component';
import {
  DialogFormNettingComponent
} from './shared/dialog-form-netting/dialog-form-netting/dialog-form-netting.component';
import {InfoDialogContainerComponent} from './shared/info-dialog-container/info-dialog-container.component';
import {DialogFormAlertComponent} from './shared/dialog-form-alert/dialog-form-alert.component';
import {ErrorPromptComponent} from './shared/error-prompt/error-prompt.component';
import {GuideComponent} from './guide/guide.component';
import {
  DialogFacturationNettingComponent
} from './core/facturations/facturation-netting-dialog/dialog-facturation-netting.component';
import {DashboardPowerBiComponent} from './core/dashboard/dashboard-power-bi/dashboard-power-bi.component';
import {PurgeModalComponent} from './core/application-settings/purge-modal/purge-modal.component';
import {Title} from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
    CheckUserAuthenticationComponent,
    CheckUserRolesComponent,
    NoAuthenticationAccessComponent,
    ApplicationManagementListComponent,
    DsiManagementListComponent,
    ServiceManagerComponent,
    MatConfirmDialogComponent,
    DialogFormNettingComponent,
    InfoDialogContainerComponent,
    DialogFormAlertComponent,
    ErrorPromptComponent,
    GuideComponent,
    DialogFacturationNettingComponent,
    DashboardPowerBiComponent,
    PurgeModalComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    SharedModule,
    UnauthorizedModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot()
  ],
  exports: [
    BrowserAnimationsModule
  ],
  providers: [
    ConfigService,
    AuthenticationService,
    OAuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService, AuthenticationService, OAuthService],
      multi: true
    },
    UserAuthenticationCheckGuard,
    UserProfileCheckGuard,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function ConfigLoader(configService: ConfigService, authenticationService: AuthenticationService, oAuthService: OAuthService): () => Promise<any> {
  return () => configService.loadConfig(environment.configFile).then(() => {
    return authenticationService.setOAuthConfig(configService, oAuthService)
  });
}
