<div class="configFile">
  <div class="title">
    <h2 class="guide-title">Fichier de configuration PackGémini</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input [(content)]="conf"></app-file-input>
    <button mat-mini-fab color="primary" (click)="downloadFile('PackgeminiV2_CDS')">
      <mat-icon>save_alt</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="importFile('conf')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>

<div class="file">
  <div class="title">
    <h2>Guide: Présentation de PackGémini</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="userGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('userGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2 class="guide-title">Guide: Domaines applicatifs</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="dsiGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('dsiGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2>Guide: Données collectées</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="dataGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('dataGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2 class="guide-title">Guide: Suivi de commande</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="commandGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('commandGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2 class="guide-title">Guide: Suivi d'application</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="appsGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('appsGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2 class="guide-title">Guide: Suivi des alertes</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="alertGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('alertGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>
<div class="file">
  <div class="title">
    <h2 class="guide-title">Guide: Administration</h2>
  </div>
  <div class="inputWithButton">
    <app-file-input class="input-space" [(content)]="adminGuide"></app-file-input>
    <button mat-mini-fab color="primary" (click)="importFile('adminGuide')">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>
<div class="file">
  <!-- enable debug pack -->
  <mat-slide-toggle [checked]="debugMode" (change)="setDebugMode($event.checked)">
    <b>Mode débogage 🛠️</b>
  </mat-slide-toggle>
  
</div>
