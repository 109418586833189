<form class="userForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Prénom</mat-label>
    <input matInput formControlName="firstName" type="text" [readonly]="asEdited">
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Nom</mat-label>
    <input matInput formControlName="lastName" type="text" [readonly]="asEdited">
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-large">
    <mat-label>Adresse Email</mat-label>
    <input matInput formControlName="email" type="text" [readonly]="asEdited">
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Identifiant</mat-label>
    <input matInput formControlName="identifier" type="text" [readonly]="asEdited">
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Profil</mat-label>
    <mat-select formControlName="profile" (ngModelChange)="profileHasChanged()" [disabled]="asEdited">
      <mat-option *ngFor="let profileValue of Object.values(UserProfile)" [value]="profileValue">
        {{ profileValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Applications</mat-label>
    <mat-select #matAppsRef formControlName="applications" multiple [disabled]="!applicationNamesEnabled()">
      <mat-option *ngFor="let name of applicationsNames" [value]="name">
        {{ name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="display: none;" appearance="outline" color="accent">
    <mat-label>ID</mat-label>
    <input matInput formControlName="id" type="text">
  </mat-form-field>

  <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && !btnStatus">Créer
  </button>
  <button mat-raised-button class="btn_annuler" type="button" color="accent" (click)="clear()">Annuler</button>
  <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && btnStatus">Valider
  </button>
  <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
  <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited" (click)="confirmDelete()">Supprimer
  </button>

</form>