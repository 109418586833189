import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CalendrierService} from '../../../calendrier-pack/calendrier.service';
import {IMonthsClos} from 'src/app/shared/models/calendrier';
import {ThemeService} from "src/app/shared/services/theme.service";


@Component({
  selector: 'app-facturation-netting_regularisation',
  templateUrl: './facturation-netting_regularisation.component.html',
  styleUrls: ['./facturation-netting_regularisation.component.less']
})


export class FacturationNettingRegularisationComponentForm implements OnInit {

  public years: number[] = [];
  public currentYear: number = new Date().getFullYear();
  public yearSelected!: number;

  @Output()
  emitYearSelected = new EventEmitter<number>();

  public months: string[] = [];
  public monthSelected!: string;

  @Output()
  emitMonthSelected = new EventEmitter<string>();

  public lastMonthClos!: number;
  public firstMonthClos!: number;
  public monthsClosByYear: IMonthsClos[] = [];

  public vues: string[] = ['Netting mois', 'Regularisation'];
  public vueSelected!: string;

  @Output()
  emitVueSelected = new EventEmitter<string>();

  @Input()
  public isFormDisabled = false;

  @Output()
  emitNetRegisShown = new EventEmitter<boolean>();


  constructor(public _calendrierService: CalendrierService, public theme: ThemeService,) {

  }

  ngOnInit(): void {
    this.vueSelected = "Netting mois";
    this.emitVueSelected.emit(this.vueSelected);
    this.displayYear();
    this.getMonthClosService();
  }

  displayYear(): void {
    for (let i = (this.currentYear - 1); i < (this.currentYear + 1); i++) {
      this.years.push(i);
    }
    this.yearSelected = this.currentYear;
    this.emitYearSelected.emit(this.yearSelected);
  }

  displayMonth(): void {
    this.monthSelected = this.lastMonthClos.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = "0" + this.monthSelected;
    }
    this.months = [];
    for (let i = this.firstMonthClos; i < (this.lastMonthClos + 1); i++) {
      let m: string = i.toString();
      if (m.length == 1) {
        m = "0" + m;
      }
      this.months.push(m);
    }
    this.emitMonthSelected.emit(this.monthSelected);
  }

  getMonthClosService(): void {
    this._calendrierService.getMonthsClos(this.yearSelected).subscribe({
      next: data => {
        if (data) {
          this.monthsClosByYear = data;
          this.lastMonthClos = this.monthsClosByYear[this.monthsClosByYear.length - 1].month;
          this.firstMonthClos = this.monthsClosByYear[0].month;
          this.displayMonth();
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });
  }


  suivantService(): void {
    //this.getNettingWithCostService(); ENVOIE VERS LIST PART DES ELEMENTS
    this.isFormDisabled = true;
    this.emitNetRegisShown.emit(true);
  }

  changeYear(year: number): void {
    this.yearSelected = year;
    this.emitYearSelected.emit(this.yearSelected);
    this.getMonthClosService();
  }

  changeMonth(month: number): void {
    this.monthSelected = month.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = "0" + this.monthSelected;
    }
    this.emitMonthSelected.emit(this.monthSelected);
  }

  changeVue(vue: string): void {
    this.vueSelected = vue;
    this.emitVueSelected.emit(this.vueSelected);
  }
}
