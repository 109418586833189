import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ROUTES} from '../shared/constants/routes';
import {UsersComponent} from './user/users/users.component';
import {ApplicationListComponent} from './application-project/application-list/application-list.component';
import {ProjectListComponent} from './application-project/project-list/project-list.component';
import {CoreComponent} from './core.component';
import {ImportDataComponent} from './import-data/import-data.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {OrderFormListComponent} from './orders-forms/order-form-list/order-form-list.component';
import {PackGenerationComponent} from './pack-generation/pack-generation/pack-generation.component';
import {UserProfileCheckGuard} from '../shared/guards/user-profile-check.guard';
import {ServiceManagerComponent} from './service-manager/service-manager.component';
import {ApplicationSettingsComponent} from './application-settings/application-settings.component';
import {DomainesListComponent} from './domaines-list/domaines-list.component';
import {CalendrierFormListComponent} from './calendrier-pack/calendrier-form-list/calendrier-form-list.component';
import {NettingFormListComponent} from './netting-account/netting-form-list/netting-form-list.component';
import {ExcludedDemandListComponent} from './excluded-demand/excluded-demand-list/excluded-demand-list.component';
import {DashboardAlertComponent} from './dashboard/dashboard-alert/dashboard-alert.component';
import {DashboardMenuComponent} from './dashboard/dashboard-menu/dashboard-menu.component';
import {DashboardOrderComponent} from './dashboard/dashboard-order/dashboard-order.component';
import {DashboardAppsComponent} from './dashboard/dashboard-apps/dashboard-apps.component';
import {GuideComponent} from '../guide/guide.component';
import {FacturationListComponent} from './facturations/facturation-list/facturation-list.component';
import {FacturationImportComponent} from './facturations/facturation-import/facturation-import.component';
import {
    FacturationNettingListComponent
} from './facturations/facturation-netting_regularisation/facturation-netting-list/facturation-netting-list.component';
import {FacturationVueNettingComponent} from './facturations/facturation-vue_netting/facturation-vue_netting.component';
import {
    NettingSchemaFormListComponent
} from './netting-schema/netting-schema-form-list/netting-schema-form-list.component';
import {DashboardPowerBiComponent} from './dashboard/dashboard-power-bi/dashboard-power-bi.component';

const routes: Routes = [
    {
        path: ROUTES.EMPTY_PATH,
        component: CoreComponent, // base template component
        children: [
            {
                path: ROUTES.ADMIN_PATH,
                redirectTo: ROUTES.ADMIN_ORDER_FOFRM_LIST_PATH,
                pathMatch: 'full'
            },
            {
                path: ROUTES.ADMIN_ORDER_FOFRM_LIST_PATH,
                component: OrderFormListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_PROJECTS_PATH,
                component: ProjectListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_DOMAINES_PATH,
                component: DomainesListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_APPS_PATH,
                component: ApplicationListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_CONTACTS_PATH,
                component: UsersComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_CALENDRIER_PATH,
                component: CalendrierFormListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_EXCLUDED_DEMAND_PATH,
                component: ExcludedDemandListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_NETTING_SCHEMA_PATH,
                component: NettingSchemaFormListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_NETTING_PATH,
                component: NettingFormListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_IMPORT_PATH,
                component: ImportDataComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.ADMIN_SETTINGS_PATH,
                component: ApplicationSettingsComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.DATA_PACK_GEN_PATH,
                component: PackGenerationComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.DASHBOARD_PATH,
                redirectTo: ROUTES.DASHBOARD_MENU_PATH,
                pathMatch: 'full'
            },
            {
                path: ROUTES.DASHBOARD_ALERT_PATH,
                component: DashboardAlertComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DASHBOARD_MENU_PATH,
                component: DashboardMenuComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DASHBOARD_ORDERS_PATH,
                component: DashboardOrderComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DASHBOARD_APPS_PATH,
                component: DashboardAppsComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DASHBOARD_NETTINGS_PATH,
                component: FacturationVueNettingComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DASHBOARD_POWER_BI_PATH,
                component: DashboardPowerBiComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.DATA_PATH,
                redirectTo: ROUTES.DATA_PACK_GEN_PATH,
                pathMatch: 'full'
            },
            {
                path: ROUTES.SM_PATH,
                redirectTo: ROUTES.SM_PROJECT_PATH,
                pathMatch: 'full'
            },
            {
                path: ROUTES.SM_PROJECT_PATH,
                component: ServiceManagerComponent,
                canActivate: [UserProfileCheckGuard]

            },
            {
                path: ROUTES.HELP_PATH,
                component: GuideComponent
            },
            {
                path: ROUTES.FACTURATION_PATH,
                redirectTo: ROUTES.FACTURATION_LIST_PATH,
                pathMatch: 'full'
            },
            {
                path: ROUTES.FACTURATION_LIST_PATH,
                component: FacturationListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.FACTURATION_IMPORT_PATH,
                component: FacturationImportComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.FACTURATION_NETTING_PATH,
                component: FacturationNettingListComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.FACTURATION_VUE_NETTING_PATH,
                component: FacturationVueNettingComponent,
                canActivate: [UserProfileCheckGuard]
            },
            {
                path: ROUTES.EMPTY_PATH,
                redirectTo: ROUTES.SM_PROJECT_PATH,
                pathMatch: 'full',
            },
            {
                path: ROUTES.NOT_FOUND_PATH,
                component: NotFoundComponent
            },
            {
                path: ROUTES.ELSE_PATH,
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class CoreRoutingModule {
}
