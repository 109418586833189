<form class="formNettingAccount" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Nom</mat-label>
        <input matInput required formControlName="name" type="text" [readonly]="asEdited">
        <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="input-width">
        <mat-label>Code</mat-label>
        <input matInput required formControlName="code" type="text" [readonly]="asEdited">
        <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="input-width">
        <mat-label>Intitulé</mat-label>
        <input matInput required formControlName="intitule" type="text" [readonly]="asEdited">
        <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Année pivot</mat-label>
        <input matInput formControlName="annee_pivot" type="number" [readonly]="asEdited">
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Nouveau code</mat-label>
        <input matInput formControlName="nouveau_code" type="text" [readonly]="asEdited">
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Intitulé Nouveau code</mat-label>
        <input matInput formControlName="intitule_nouveau_code" type="text" [readonly]="asEdited">
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Schéma</mat-label>
        <mat-select formControlName="schema_name" [disabled]="asEdited">
            <mat-option *ngFor="let schema_name of schemas_names" [value]="schema_name">{{schema_name}}</mat-option>
        </mat-select>
    </mat-form-field>


    <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
        *ngIf="!asEdited && !btnStatus">Créer
    </button>
    <button mat-raised-button class="btn_annuler" color="accent" type="button" (click)="clear()">Annuler</button>
    <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
        *ngIf="!asEdited && btnStatus">Valider
    </button>
    <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
    <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited" (click)="confirmDelete()">Supprimer
    </button>

</form>