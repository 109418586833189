import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {INettingSchema} from 'src/app/shared/models/netting-schema';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {NettingService} from '../../netting-account/netting.service';
import {NettingSchemaService} from '../nettingschema.service';


@Component({
  selector: 'app-netting-schema-form',
  templateUrl: './netting-schema-form.component.html',
  styleUrls: ['./netting-schema-form.component.less']
})
export class NettingSchemaFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  empty_compte_imputes!: string;
  public natures: string[] = [];
  public compte_imputes: string[] = [this.empty_compte_imputes];


  asEdited = false;
  btnStatus = false;

  @Input()
  netting_schema!: INettingSchema;

  @Input()
  nettingsSchemas: any[] = [];

  @Output()
  updateEvent = new EventEmitter<INettingSchema>();

  @Output()
  clearEvent = new EventEmitter<any>();

  constructor(private _fb: UntypedFormBuilder, private _ns: NettingService, private _nss: NettingSchemaService, private _snackBar: MatSnackBar,
              private dialogService: DialogService) {
    this.formGroup = this._fb.group({
      id: [''],
      name: ['', Validators.required],
      line: ['', Validators.required],
      nature: ['', Validators.required],
      na_name: [''],
      line_ref: ['', Validators.required],
      taux1: ['', Validators.required],
      pivotal_year: [''],
      taux2: [''],
      isEnabled: ['']

    })

  }

  ngOnInit(): void {
    this.natures = ["GOUV-CAP", "GOUV-Sogeti", "Projet", "PROV-RFA"];
    this.getComptes();

  }

  getComptes(): void {
    this._ns.getNettingNames().subscribe({
      next: data => {
        if (data) {
          this.compte_imputes = [...data];
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });

  }

  updateFormGroup() {
    const pUpdate = {
      id: this.netting_schema.id,
      name: this.netting_schema.name,
      line: this.netting_schema.line,
      nature: this.netting_schema.nature,
      na_name: this.netting_schema.na_name,
      line_ref: this.netting_schema.line_ref,
      taux1: this.netting_schema.taux1,
      pivotal_year: this.netting_schema.pivotal_year,
      taux2: this.netting_schema.taux2,
      isEnabled: this.netting_schema.isEnabled
    }
    this.formGroup.setValue(pUpdate);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.netting_schema) return;

    this.asEdited = false;
    if (!this.netting_schema || !this.netting_schema.hasOwnProperty('line') || this.netting_schema.isEnabled && this.netting_schema.name === '') {
      this.updateFormGroup()
      this.asEdited = false;
      this.btnStatus = false;
    } else if (this.netting_schema.isEnabled && this.netting_schema.name != '') {
      this.updateFormGroup()
      this.asEdited = true;
      this.btnStatus = false;


    } else if (!this.netting_schema.isEnabled && this.netting_schema.name != '') {
      this.updateFormGroup()
      this.asEdited = false;
      this.btnStatus = true;

    }
  }

  /*
   * Submit NettingForm
   */

  onSubmit(): void {

    if (!this.asEdited && !this.btnStatus) {
      this._nss.createNettingSchema(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
        },
        error: err => {
          this.openSnackBar(err)
        },
        complete: () => {
        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      this._nss.updateNettingSchema(this.netting_schema.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
        },
        error: err => {
          this.openSnackBar(err)
        },
        complete: () => {
        }
      })
    }
  }

  /*
  * Show error in a notification bar
  */
  openSnackBar(err: string) {
    this._snackBar.open('ERREUR', err, {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  /*
   * Initialize orderform component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false
    this.clearEvent.emit();
  }

  is_edit(asEdited: boolean) {

    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }

  /*
   * Delete project form
   */
  deleteProjectForm() {
    if (this.asEdited) {
      this._nss.deleteNettingSchema(this.netting_schema).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
        },
        error: err => {
          this.openSnackBar(err)
        },
        complete: () => {
        }
      });
    }
  }

  /*
   * Pop Confirmation
   */
  confirmDelete() {

    this.dialogService.openConfirmDialog(this.netting_schema.name)
      .afterClosed().subscribe({
      next: res => {
        this.deleteProjectForm();
      },
      error: err => {
        this.openSnackBar(err)
      },
      complete: () => {
      }
    });
  }
}
