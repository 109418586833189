<div class="card">
  <app-user-form *ngIf="authenticationService.isUserAdmin()" [user]="userSelected"
    [applicationsNames]="applicationsNames" (updateEvent)="updateUsersList()" (clearEvent)="initUser()"></app-user-form>
  <div class="data-header">
    <mat-form-field class="userFilter">
      <mat-label>Filtre</mat-label>
      <input matInput (keyup)="usersFilter($event)" #input>
    </mat-form-field>
    <div class="exportBtn">
      <button mat-raised-button class="indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="exportData()">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
    </div>
  </div>

  <mat-table #table class="mat-elevation-z8 table-container" *ngIf="users" [dataSource]="users" matSort>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="firstName"> Prénom</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.firstName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="lastName"> Nom</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.lastName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="identifier">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="identifier"> Identifiant</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.identifier }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="profile">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="profile"> Profil</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.profile }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="applications">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="applications"> Applications</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ getApplicationsNames(element.applications) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="email"> Adresse email</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef id="actions" class="actions-td"> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="actions-td">
        <button mat-icon-button (click)="selectedUserPencel(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-icon-button (click)="removeUser(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedUser(row)"></mat-row>
  </mat-table>
</div>