<div class="card">
    <app-netting-schema-form
            [netting_schema]=netting_schemaSelected
            (updateEvent)="updateList($event)"
            (clearEvent)="clearSelected()"
            [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}">
    </app-netting-schema-form>

    <div class="data-header">
        <mat-form-field>
            <mat-label>Filtre</mat-label>
            <input matInput (keyup)="filterList($event)" #input>
        </mat-form-field>
        <button mat-raised-button [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
                (click)="exportData()" class="indigo" [style.background-color]="theme.getColor('Main Dark')">
            <mat-icon>get_app</mat-icon>
            Export Excel
        </button>
    </div>

    <mat-table class="light mat-elevation-z4" [dataSource]="netting_schemas" matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="name">Nom</th>
            <td *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="line">Ligne</th>
            <td *matCellDef="let element">{{ element.line }}</td>
        </ng-container>

        <ng-container matColumnDef="nature">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="nature">Nature Imputation</th>
            <td *matCellDef="let element">{{ element.nature }}</td>
        </ng-container>

        <ng-container matColumnDef="na_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="na_name">Compte Imputé</th>
            <td *matCellDef="let element">{{ element.na_name }}</td>
        </ng-container>

        <ng-container matColumnDef="line_ref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="line_ref">Ligne Ref</th>
            <td *matCellDef="let element">{{ element.line_ref }}</td>
        </ng-container>

        <ng-container matColumnDef="taux1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="taux1">1er Taux</th>
            <td *matCellDef="let element">{{ element.taux1 }}</td>
        </ng-container>

        <ng-container matColumnDef="pivotal_year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="pivotal_year">Année pivot</th>
            <td *matCellDef="let element">{{ element.pivotal_year }}</td>
        </ng-container>

        <ng-container matColumnDef="taux2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="taux2">2nd taux</th>
            <td *matCellDef="let element">{{ element.taux2 }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef id="actions">Actions</th>
            <td *matCellDef="let element" class="actions-td">
                <div>
                    <button mat-icon-button aria-label="delete" (click)="onEditRowPencel(element)"
                            [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="delete" (click)="onDeleteRow(element)"
                            [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="focusable" *matRowDef="let row; columns: displayedColumns;" (click)="onEditRow(row)"></tr>
    </mat-table>

</div>
