<div class="card">
    <app-netting-form [netting]=nettingSelected (updateEvent)="updateList($event)" (clearEvent)="clearSelected()"
        [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"></app-netting-form>

    <div class="data-header">
        <mat-form-field class="nettingFilter">
            <mat-label>Filtre</mat-label>
            <input matInput (keyup)="filterList($event)">
        </mat-form-field>
        <div class="exportBtn">
            <button mat-raised-button (click)="exportData()" class="indigo"
                [style.background-color]="theme.getColor('Main Dark')">
                <mat-icon>get_app</mat-icon>
                Export Excel
            </button>
        </div>
    </div>

    <mat-table #table class="mat-elevation-z8 table-container" [dataSource]="nettings" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="name">Nom</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="code">Code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="intitule">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="intitule">Intitulé</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.intitule }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="annee_pivot">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="annee_pivot">Année pivot</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.annee_pivot }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="nouveau_code">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="nouveau_code">Nouveau code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.nouveau_code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="intitule_nouveau_code">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="intitule_nouveau_code">Intitulé nouveau
                code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.intitule_nouveau_code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="schema_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="schema_name">Schéma</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.schema_name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef id="actions">Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" class="actions-td">
                <div class="btn_actions">
                    <button mat-icon-button aria-label="delete" (click)="onEditRowPencel(element)"
                        [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="delete" (click)="onDeleteRow(element)"
                        [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEditRow(row)"></mat-row>
    </mat-table>
</div>