import {Component, OnInit} from '@angular/core';
import {AlertCode, AlertLevel, AlertVue, IAlert,} from 'src/app/shared/models/alert';
import {AlertService} from '../../import-data/jira-import/services/alert.service';
import {JiraImportService} from '../../import-data/jira-import/services/jira-import.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {ThemeService} from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-dashboard-alert',
  templateUrl: './dashboard-alert.component.html',
  styleUrls: ['./dashboard-alert.component.less'],
})
export class DashboardAlertComponent implements OnInit {
  data: IAlert[] = [];
  modes: boolean[] = [true, false, false, false];
  demandFilter = '';

  public codes: string[] = [
    AlertCode.A01,
    AlertCode.A02,
    AlertCode.A03,
    AlertCode.A04,
    AlertCode.A05,
    AlertCode.A06,
    AlertCode.A07,
    AlertCode.A08,
    AlertCode.A10,
    AlertCode.A12,
    AlertCode.A14,
    AlertCode.A16,
    AlertCode.A17,
    AlertCode.A18,
    AlertCode.A19,
    AlertCode.A20,
    AlertCode.A21,
    AlertCode.A26,
    AlertCode.A09,
    AlertCode.A13,
    AlertCode.A15,
    AlertCode.A22,
    AlertCode.A23,
    AlertCode.A24,
    AlertCode.A25,
  ];

  public codesSelection: boolean[] = [];
  public codesCrit: AlertLevel[] = [
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Majeur,
    AlertLevel.Avertissement,
    AlertLevel.Avertissement,
    AlertLevel.Avertissement,
    AlertLevel.Information,
    AlertLevel.Information,
    AlertLevel.Information,
    AlertLevel.Information,
  ];
  public codesCount: number[] = [];

  DISPLAYED_COLUMNS: string[] = [
    'severity',
    'code',
    'comment',
    'dnumber',
    'ddescription',
    'application',
    'dusername',
    'dstatus',
    'projectCode',
    'serviceType',
    'reference',
    'datetime',
  ];
  spinner = false;

  constructor(
    public alertService: AlertService,
    public jiraImportService: JiraImportService,
    public theme: ThemeService,
    public applicationSettingsService: ApplicationSettingsService,
    public authentificationService: AuthenticationService,
    private snackbarService: SnackbarService,
  ) {
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 


  ngOnInit(): void {
    this.spinner = true;
    const test = this.authentificationService.getUser().applications;
    const tab: string[] = [];
    test.forEach((element) => {
      tab.push(element.name + '');
      tab.push(element.label + '');
    });

    this.alertService.getAlerts(tab).subscribe({
      next: (data) => {
        this.data = data.sort(this.compareAlerts);
        data.forEach((e) => {
          e.dstatus = e.dstatus?.replace('Ã©', 'é');
        });
        this.swap(1);
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de la récupération des alertes");
      },
      complete: () => {
        this.spinner = false;
      }
    });
    this.codes.forEach((element) => {
      this.codesSelection.push(false);
    });
  }

  statusColor(element: IAlert) {
    if (element?.dstatus) {
      switch (element.dstatus) {
        case 'Evalué':
        case 'Chiffré':
        case 'Approuvé':
        case 'Engagé':
        case 'Analysé':
        case 'En attente':
        case 'Implémenté':
        case 'Testé':
          return 'state-blue';
        case 'Abandonné':
          return 'state-red';
        case 'Fermé':
        case 'Livré':
        case 'Réceptionné':
        case 'Accepté':
        case 'Mise en production':
          return 'state-green';
        default:
          break;
      }
    }
    return 'status-grey';
  }

  swap(i: number) {
    this.modes[1] = false;
    this.modes[2] = false;
    this.modes[3] = false;
    this.modes[4] = false;

    this.modes[i] = true;

    this.codesSelection = [];
    this.codes.forEach((element) => {
      this.codesSelection.push(false);
    });
    this.updateFilterList();
  }

  isCodeActive(alert: IAlert) {
    const trueCodes: string[] = [];
    let i = 0;
    this.codes.forEach((element) => {
      if (this.codesSelection[i]) {
        trueCodes.push(element);
      }
      i++;
    });

    if (trueCodes.length == 0) return true;
    else if (trueCodes.indexOf(alert.code + '') != -1) return true;
    return false;
  }

  isActive(alert: IAlert) {
    if (this.modes[1] || this.modes[2] || this.modes[3] || this.modes[4]) {
      switch (alert.type) {
        case AlertVue.ADMIN:
          if (!this.modes[4]) return false;
          break;
        case AlertVue.BEB:
          if (!this.modes[2]) return false;
          break;
        case AlertVue.CDS:
          if (!this.modes[3]) return false;
          break;
        case AlertVue.DSI:
          if (!this.modes[1]) return false;
          break;
      }
    }
    return !(this.demandFilter != null &&
      !alert.dnumber
        ?.toUpperCase()
        .trim()
        .includes(this.demandFilter.trim().toUpperCase()));
  }

  compareAlerts(a: IAlert, b: IAlert): number {
    if (a.severity != b.severity) {
      let aComp = 0;
      let bComp = 0;
      switch (a.severity) {
        case AlertLevel.Bloquant:
        case AlertLevel.Majeur:
          aComp = 2;
          break;
        case AlertLevel.Avertissement:
          aComp = 1;
          break;
        case AlertLevel.Information:
          aComp = 0;
          break;
      }
      switch (b.severity) {
        case AlertLevel.Bloquant:
        case AlertLevel.Majeur:
          bComp = 2;
          break;
        case AlertLevel.Avertissement:
          bComp = 1;
          break;
        case AlertLevel.Information:
          bComp = 0;
          break;
      }
      if (aComp < bComp) return 1;
      if (aComp > bComp) return -1;
      return 0;
    } else {
      if (a.dnumber + '' > b.dnumber + '') return 1;
      if (a.dnumber + '' < b.dnumber + '') return -1;
      return 0;
    }
  }

  updateFilterList() {
    this.codesCount = [];
    this.codes.forEach((element) => {
      this.codesCount.push(0);
    });
    this.data.forEach((e) => {
      if (this.isActive(e)) {
        this.codesCount[this.codes.indexOf(e.code + '')]++;
      }
    });
  }

  exportData() {
    this.spinner = true;
    const test = this.authentificationService.getUser().applications;
    const tab: string[] = [];
    test.forEach((element) => {
      tab.push(element.name + '');
      tab.push(element.label + '');
    });

    this.jiraImportService
      .getAlertCsvByUser(
        tab + '',
        this.authentificationService.getUser().profile + '',
      )
      .subscribe({
        next: (res) => {
          if (res) {
            const blob: any = new Blob([res], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            window.URL.createObjectURL(blob);
            fileSaver.saveAs(
              blob,
              'Alerte Jira - ' +
              this.applicationSettingsService.applicationSettings.CDS_name +
              ' - ' +
              new Date().toLocaleDateString() +
              ' ' +
              new Date().toLocaleTimeString(),
            );
          } else {
            this.openSuccessSnackBar('Aucunes alertes à exporter');
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar("Erreur lors de l'export des alertes");
        },
        complete: () => {
          this.spinner = false;
        }
      });
  }

  isEnabled(type: string): boolean {
    switch (this.authentificationService.getUser().profile) {
      case UserProfile.ADMIN:
      case UserProfile.MANAGER_CDS:
        return true;
      case UserProfile.CP_CDS:
      case UserProfile.GUEST_CDS:
        if (type == 'DSI' || type == 'BEB' || type == 'CDS') return true;
        break;
      case UserProfile.CP_SNCF:
      case UserProfile.GUEST_SNCF:
      case UserProfile.SERVICE_MANAGER:
        if (type == 'DSI') return true;
        break;
    }
    return false;
  }

  isAnyActive(): number {
    let res = 0;
    this.data.forEach((element) => {
      if (this.isActive(element)) {
        res++;
      }
    });
    return res;
  }
}
