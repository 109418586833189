<mat-spinner *ngIf="spinner"></mat-spinner>
<div class="oparation" [ngClass]="{'spinner': spinner}">
	<div id="leftSection-import">
		<div class="lb_name_size">Importer les factures </div>
		<app-file-input   [(content)]="invoiceFile"></app-file-input>
		<button mat-raised-button class="input mLeft" type="submit" (click) = "uploadFile($event)">Importer</button>
	</div>

	<div id="rightSection-import">
		<div class="lb_name_size">Exporter les factures </div>
		<br>

		<mat-form-field appearance="outline">
			<mat-label>Année</mat-label>
			<mat-select [(ngModel)]="yearSelected" (selectionChange)="changeYear($event.value)">
				<mat-option></mat-option>
				<mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
			</mat-select>
		</mat-form-field>

		<button mat-raised-button class="indigo input mLeft"  [style.background-color]="theme.getColor('Main Dark')" type="submit" [disabled]="!canExport" (click) = "exportInvoice(yearSelected)">Exporter</button>

		<mat-table class="light mat-elevation-z4" [dataSource]="invoiceSummary" matSort>

			<!-- month Column -->
			<ng-container matColumnDef="month" >
				<th mat-header-cell *matHeaderCellDef  > Mois Pack </th>
				<td mat-cell *matCellDef="let element"> <strong style="color: brown;"> {{adaptVisualDate(element.invoicesMonth)}} </strong>  </td>
			</ng-container>->

			<!-- totalCharged Column -->
			<ng-container matColumnDef="totalInvoice">
				<th mat-header-cell *matHeaderCellDef > Total facturé </th>
				<td mat-cell *matCellDef="let element"> {{element.invoicesAmount | number:'1.1-2':"fr-FR"}} € </td>
			</ng-container>

			<!-- nbOfInvoices Column -->
			<ng-container matColumnDef="nbInvoices">
				<th mat-header-cell *matHeaderCellDef> Nb de factures </th>
				<td mat-cell *matCellDef="let element"> {{element.invoicesCount}}  </td>
			</ng-container>

			<tr  mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true;"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

		</mat-table>
	</div>

</div>
