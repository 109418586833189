<div class="menu" [style.background-color]="theme.getColor('Sidebar Dark')" >
    <a href="/core/dashboard">
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')"
            [class.current]="router.url.includes('dashboard/')" href="">
            <div class="material-icons md-48" href="/dashboard">dashboard</div>
            <p>Dashboard</p>
        </div>
    </a>
    <a href="/core/service-management">
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')"
            [class.current]="router.url.includes('service-management/')" href="">
            <div class="material-icons md-48" href="/core/service-management">storage</div>
            <p>Domaines Applicatifs</p>
        </div>
    </a>
    <a href="/core/data">
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')"
            [ngStyle]="isCollectedDataTabHidden()?  {'display':'none'}:{}"
            [class.current]="router.url.includes('data/')" href="">
            <div class="material-icons md-48" href="/core/data">source</div>
            <p>Données collectées</p>
        </div>
    </a>
    <a href="/core/facturation">
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')"
            [ngStyle]="isFacturationHidden() || config.getConfiguration().experimentalEnable === 'false' ? {'display':'none'}:{}"
            [class.current]="router.url.includes('facturation/')">
            <div class="material-icons md-48">library_books</div>
            <p>Facturation</p>
        </div>
    </a>
    <a href="/core/admin">
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')"
            [ngStyle]="!isAdministrationTabShowable()? {'display':'none'}:{}"
            [class.current]="router.url.includes('admin/')">
            <div class="material-icons md-48">settings</div>
            <p>Administration</p>
        </div>
    </a>
    <a>
        <div class="section" [style.background-color]="theme.getColor('Sidebar Dark')" (click)="openGuide('userGuide')">
            <div class=" material-icons md-48">help</div>
            <p>Guide d'utilisation</p>
        </div>
    </a>
<div>

    