import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {INettingSchema} from 'src/app/shared/models/netting-schema';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {NettingService} from '../../netting-account/netting.service';
import {NettingSchemaService} from '../nettingschema.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-netting-schema-form-list',
  templateUrl: './netting-schema-form-list.component.html',
  styleUrls: ['./netting-schema-form-list.component.less']
})
export class NettingSchemaFormListComponent implements OnInit {

  netting_schemas!: MatTableDataSource<INettingSchema>;
  netting_schemaSelected!: INettingSchema;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = ["name", "line", "nature", "na_name", "line_ref", "taux1", "pivotal_year", "taux2", "actions"];

  constructor(private _ns: NettingService,
              private _nss: NettingSchemaService,
              public theme: ThemeService,
              public authenticationService: AuthenticationService,
              private dialogService: DialogService,
              private applicationSettingsService: ApplicationSettingsService) {
    this.netting_schemas = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getNettingSchemaService();
  }

  ngAfterViewInit() {
    this.netting_schemas.sort = this.sort;
  }

  getNettingSchemaService(): void {
    this._nss.getNettingSchemas().subscribe({
      next: data => {
        this.netting_schemas.data = data;
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  updateList(data: INettingSchema): void {
    this.getNettingSchemaService();
  }

  clearSelected() {
    this.netting_schemaSelected = {
      id: '',
      name: '',
      line: undefined,
      nature: '',
      na_name: undefined,
      line_ref: undefined,
      taux1: undefined,
      pivotal_year: undefined,
      taux2: undefined,
      isEnabled: false
    };
  }

  filterList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.netting_schemas.filter = filterValue.trim().toLowerCase();
  }

  onEditRowPencel(row: INettingSchema): void {
    this.netting_schemaSelected = row;
    this.netting_schemaSelected.isEnabled = false
  }

  onEditRow(row: INettingSchema): void {
    this.netting_schemaSelected = row;
    this.netting_schemaSelected.isEnabled = true;
  }

  onDeleteRow(row: INettingSchema): void {
    this.dialogService.openConfirmDialog(row.name)
      .afterClosed().subscribe(res => {
      if (res) {
        this._nss.deleteNettingSchema(row).subscribe({
          next: data => {
            this.getNettingSchemaService();
            this.clearSelected()
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
      }
    });
  }

  exportData() {
    this._nss.exportDataNettingSchema().subscribe({
      next: data => {
        const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, "NettingShema - " + this.applicationSettingsService.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-'));
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }


}
