import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../services/users.service';
import { IUser } from '../../../shared/models/user';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { IApplication } from 'src/app/shared/models/application';
import { ApplicationProjectService } from '../../application-project/services/application-project.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less'],
})
export class UsersComponent implements OnInit {
  users!: MatTableDataSource<IUser>;

  userSelected!: IUser;

  applicationsNames!: string[];

  @ViewChild(MatSort)
  sort!: MatSort;

  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'identifier',
    'profile',
    'applications',
    'email',
    'actions',
  ];

  constructor(
    public usersService: UsersService,
    public authenticationService: AuthenticationService,
    public applicationProjectService: ApplicationProjectService,
    private dialogService: DialogService,
    public theme: ThemeService,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService,
  ) {
    this.users = new MatTableDataSource();
    this.loadUsers();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  ngOnInit(): void {
    this.applicationProjectService
      .getApplicationsNames()
      .subscribe({
        next: (applicationsNames) => {
          this.applicationsNames = applicationsNames;
        },
        error: err => {
          console.log(err)
        },
        complete: () => {
        }
      });
  }

  loadUsers() {
    this.usersService
      .getAllContacts()
      .subscribe({
        next: (value) => {
          this.users.data = value
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {

        }
      });
  }

  exportData() {
    this.usersService.exportData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Contacts - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar('Utilisateurs exportés avec succès');
        } else {
          this.openErrorSnackBar('Aucun utilisateur à exporter');
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de l'export des utilisateurs");
      },
      complete: () => {
      }
    });
  }

  selectedUser(user: IUser): void {
    this.userSelected = user;
    this.userSelected.check = true;
  }

  selectedUserPencel(user: IUser): void {
    this.userSelected = user;
    this.userSelected.check = false;
  }

  initUser(): void {
    this.userSelected = {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      identifier: '',
    };
  }

  ngAfterViewInit() {
    this.users.sort = this.sort;
  }

  updateUsersList(): void {
    this.loadUsers();
  }

  removeUser(row: IUser): void {
    this.dialogService
      .openConfirmDialog(row.identifier)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.usersService.deleteContact(row).subscribe({
            next: (data) => {
              this.loadUsers();
              this.initUser();
              this.openSuccessSnackBar('Utilisateur supprimé avec succès');
            },
            error: (err) => {
              console.log(err);
              this.openErrorSnackBar(
                "Erreur lors de la suppression de l'utilisateur",
              );
            },
            complete: () => {
            }
          });
        }
      });
  }

  usersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
  }

  getApplicationsNames(applications: IApplication[]) {
    return applications
      ? applications.map((application) => application.name).join(', ')
      : '';
  }
}
