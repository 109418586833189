import {Component, OnInit} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import * as fileSaver from "file-saver";
import {ApplicationSettings} from "src/app/shared/models/application-settings";
import {IInvoiceSummary} from "src/app/shared/models/invoices";
import {ThemeService} from "src/app/shared/services/theme.service";
import {ApplicationSettingsService} from "../../application-settings/application-settings.service";
import {FacturationService} from "../services/facturation.service";
import { SnackbarService } from '../../../shared/services/snackbar.service';


@Component({
  selector: 'app-facturation-import',
  templateUrl: './facturation-import.component.html',
  styleUrls: ['./facturation-import.component.less']
})

export class FacturationImportComponent implements OnInit {

  public invoiceFile!: File;
  public yearSelected!: number;
  public years: number[] = [];
  public canExport = true;
  public currentYear: number = new Date().getFullYear();
  public spinner = false;
  public invoiceSummary!: MatTableDataSource<IInvoiceSummary>;
  public monthNames: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"];
  public displayedColumns: string[] = ["month", "totalInvoice", "nbInvoices"];

  public month!: string;
  private applicationSettings: ApplicationSettings;

  constructor(private applicationSettingsService: ApplicationSettingsService,
              private facturationService: FacturationService,
              public theme: ThemeService,
              private snackbarService: SnackbarService) {

    this.applicationSettings = this.applicationSettingsService.getApplicationSettings();
    for (let i = (this.currentYear - 2); i < (this.currentYear + 2); i++) {
      this.years.push(i);
    }

    this.yearSelected = this.currentYear;
    this.invoiceSummary = new MatTableDataSource<IInvoiceSummary>();
  }

  ngOnInit(): void {
    this.updateInvoiceSummary();

  }

  updateInvoiceSummary() {
    this.facturationService.getInvoiceSummary(this.yearSelected).subscribe({
      next: (res: IInvoiceSummary[]) => {
        this.invoiceSummary.data = res;
        console.log(res);
      },
      error: (err: any) => {
        console.log(err);
        this.openErrorSnackBar('Erreur lors de la récupération des données');
      },
      complete: () => {
      }
    });
  }

  uploadFile(event: any) {
    if (this.applicationSettings.JIRA_Radical != null
      && this.applicationSettings.JIRA_Radical.trim() != ""
      && this.invoiceFile.name.indexOf(this.applicationSettings.JIRA_Radical.trim()) == -1) {
      this.openErrorSnackBar('Import impossible: Le nom de fichier ' + this.invoiceFile.name + ' ne correspond pas au radical indiqué.')
    } else {
      this.spinner = true;

      this.facturationService.uploadInvoiceFile(this.invoiceFile).subscribe({
        next: (data: string) => {
          this.openSuccessSnackBar("Le fichier s'est correctement importé.");
        },
        error: (err: string) => {
          console.log(err);
          this.openErrorSnackBar("Le fichier n'est pas importé.");
        },
        complete: () => {
          this.spinner = false;
        }
      });
    }

  }

  exportInvoice(year: number): void {
    this.spinner = true;

    this.facturationService.exportInvoice(year).subscribe({
      next: data => {
        const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, "Factures " + year + " (" + this.applicationSettingsService.applicationSettings.CDS_name + ")");
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

  changeYear(year: number): void {
    this.yearSelected = year;
    this.updateInvoiceSummary();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  adaptVisualDate(date: string): string {
    let visual = '';

    if (date !== '1979-01-01') {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      visual = this.monthNames[Number(month) - 1] + ' - ' + year
    } else {
      visual = 'Total';
    }

    return visual;
  }


}
