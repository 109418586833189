import {Component} from '@angular/core';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {ConfigService} from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.less']
})
export class DashboardMenuComponent {

  constructor(public authenticationService: AuthenticationService, public theme: ThemeService, public config: ConfigService) {
  }

  isSuiviNettingsHidden() {
    return this.authenticationService.getUser().profile != UserProfile.ADMIN && this.authenticationService.getUser().profile != UserProfile.MANAGER_CDS;
  }

}
