import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {IApplication} from 'src/app/shared/models/application';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {IUser} from 'src/app/shared/models/user';
import {UsersService} from '../services/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApplicationProjectService} from '../../application-project/services/application-project.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.less'],
})
export class UserFormComponent implements OnInit {
  public formGroup: UntypedFormGroup;

  applicationsNamesEnabled = false;

  @ViewChild('matAppsRef') matAppsRef!: MatSelect;

  asEdited = false;
  btnStatus = false;

  @Output()
  updateEvent = new EventEmitter<IUser>();

  @Output()
  clearEvent = new EventEmitter<any>();

  @Input()
  user!: IUser;

  @Input()
  applicationsNames!: string[];

  get UserProfile() {
    return UserProfile;
  }

  get Object() {
    return Object;
  }

  constructor(
    private _as: UsersService,
    private aps: ApplicationProjectService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<number>,
    private formBuilder: UntypedFormBuilder,
    public authenticationService: AuthenticationService,
    private dialogService: DialogService,
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      identifier: ['', Validators.required],
      profile: [''],
      applications: [''],
      id: [''],
    });
  }

  ngOnInit(): void {
    this.aps.getApplicationsNames().subscribe({
      next: (applicationsNames) => {
        this.applicationsNames = applicationsNames;
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de la récupération des applications");
      },
      complete: () => {

      }
    });
  }

  onSubmit(): void {
    let allApps: IApplication[] = [];
    const apps: IApplication[] = [];

    this.aps.getApplications().subscribe({
      next: (value) => {
        allApps = value;

        if (this.formGroup.value.applications) {
          this.formGroup.value.applications.forEach((app: IApplication) => {
            const name = app + '';
            apps.push(this.applicationFromName(name, allApps));
          });
        }

        this.formGroup.value.applications = apps;

        if (!this.asEdited && !this.btnStatus) {
          this._as.postContact(this.formGroup.value).subscribe({
            next: (data) => {
              this.updateEvent.emit(data);
              this.formGroup.reset();
              this.openSuccessSnackBar("L'utilisateur a bien été créé");
            },
            error: (err) => {
              console.log(err);
              this.openErrorSnackBar("Erreur lors de la création de l'utilisateur");
            },
            complete: () => {

            }
          });
        } else if (!this.asEdited && this.btnStatus) {
          this._as.putContact(this.formGroup.value).subscribe({
            next: (data) => {
              this.updateEvent.emit();
              this.formGroup.reset();
              this.asEdited = false;
              this.btnStatus = false;
              this.openSuccessSnackBar("L'utilisateur a bien été mis à jour");
            },
            error: (err) => {
              console.log(err);
              this.openErrorSnackBar("Erreur lors de la mise à jour de l'utilisateur");
            },
            complete: () => {
            }
          });
        }
      },

      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de la récupération des applications");
      },
      complete: () => {
      }
    });
  }

  public applicationFromName(app: string, apps: IApplication[]): IApplication {
    let res: IApplication = apps[0];
    apps.forEach((a: IApplication) => {
      if (app == a.name) {
        res = a;
      }
    });
    return res;
  }

  public applicationNamesEnabled() {
    if (this.formGroup.value['profile'] != null)
      return (
        this.formGroup.value['profile'] == UserProfile.CP_SNCF ||
        this.formGroup.value['profile'] == UserProfile.CP_CDS
      );

    return false;
  }

  public profileHasChanged() {
    const profile = this.formGroup.value['profile'];
    if (profile == UserProfile.CP_SNCF || profile == UserProfile.CP_CDS) {
      this.applicationsNamesEnabled = true;
    }
    if (profile == '') {
      this.applicationsNamesEnabled = false;
      this.formGroup.value['applications'] = [];
      this.matAppsRef.options.forEach((data: MatOption) => data.deselect());
    }
  }

  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false;
    this.updateEvent.emit();
  }

  deleteUser() {
    if (this.asEdited) {
      this._as.deleteContact(this.user).subscribe({
        next: (data) => {
          this.clear();
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("L'utilisateur a bien été supprimé");
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar("L'utilisateur n'a pas pu être supprimé");
        },
        complete: () => {
        }
      });
    }
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;

    if (this.user && this.user.id !== '') {
      if (this.user.check) {
        this.asEdited = true;
      } else {
        this.asEdited = false;
        this.btnStatus = true;
      }

      const applications: IApplication[] | undefined = this.user ? this.user.applications : [];
      let applicationsNames: string[] = [];
      if (applications) {
        applicationsNames = applications.map<string>((app) => app.name as string,);
      }
      this.applicationsNamesEnabled =
        this.user?.profile === UserProfile.CP_SNCF ||
        this.user?.profile === UserProfile.CP_CDS;

      const pUpdate = {
        id: this.user ? this.user.id : '',
        firstName: this.user ? this.user.firstName : '',
        lastName: this.user ? this.user.lastName : '',
        email: this.user ? this.user.email : '',
        identifier: this.user ? this.user.identifier : '',
        profile: this.user ? this.user.profile : '',
        applications: applicationsNames,
      };
      this.formGroup.setValue(pUpdate);
    } else {
      this.clear();
    }
  }

  is_edit(asEdited: boolean) {
    asEdited = !asEdited;
    this.asEdited = asEdited;
    this.btnStatus = true;
  }

  // popUp confirmation
  confirmDelete() {
    this.dialogService
      .openConfirmDialog(this.user.identifier)
      .afterClosed()
      .subscribe({
        next: (res) => {
          this.deleteUser();
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
        }
      });
  }
}
