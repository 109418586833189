import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from 'src/app/shared/constants/routes';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {ConfigService} from 'src/app/shared/services/config.service';
import {ApplicationProjectService} from './core/application-project/services/application-project.service';
import {IApplication} from './shared/models/application';
import {IUser, User} from './shared/models/user';
import {AuthenticationService} from './shared/services/authentication.service';
import {Constants} from './shared/constants/Constants';
import {ThemeService} from './shared/services/theme.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UsersService} from "./core/user/services/users.service";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import { SnackbarService } from './shared/services/snackbar.service';


@Component({
  selector: 'no-auth-access',
  styleUrls: ['./no-authentication-access.component.less'],
  templateUrl: './no-authentication-access.component.html',
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class NoAuthenticationAccessComponent implements OnInit {

  identifierFormGroup!: UntypedFormGroup;

  public navbarColor = "";

  public profile: UserProfile = UserProfile.ADMIN;

  public selectedApplicationsNames: string[] = [];

  public applicationsNames: string[] = [];
  public identifier = '';

  public userAllowed: IUser | undefined;

  canAccess = false;

  get UserProfile() {
    return UserProfile;
  }

  get Object() {
    return Object;
  }

  @Output() onUserFound = new EventEmitter<boolean>();

  constructor(public router: Router,
              private configService: ConfigService,
              public theme: ThemeService,
              private applicationProjectService: ApplicationProjectService,
              private authenticationService: AuthenticationService,
              private _formBuilder: UntypedFormBuilder,
              private userService: UsersService,
              private snackbarService: SnackbarService,
  ) {
  }

  public ngOnInit() {
    if (this.configService.isAuthEnabled()) {
      this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
    } else {
      this.identifierFormGroup = this._formBuilder.group({
        identifier: ['', Validators.required]
      });

      this.applicationProjectService.getApplicationsNames().subscribe(applicationsNames => {
        this.applicationsNames = applicationsNames;
      });
    }
    this.navbarColor = this.theme.getColor(Constants.PackColor.MAIN_DARK);
  }

  public allowAccess() {
    this.userService.getUserByIdentifier(this.identifierFormGroup.value.identifier.toUpperCase()).subscribe({
        next: user => {
          this.userAllowed = user;
          this.canAccess = true;
        },
        error: error => {
          console.log(error);
          this.openErrorSnackBar("Vous n’êtes pas autorisé à accéder à l’application. Veuillez contacter votre administrateur.")
        },
        complete: () => {
        }
      }
    );
  }

  public validateAccess() {
    const user: User = {
      lastName: this.userAllowed?.lastName,
      firstName: this.userAllowed?.firstName,
      identifier: this.userAllowed?.identifier,
      profile: this.profile,
      applications: this.selectedApplicationsNames.map(name => {
        return {name: name} as IApplication;
      }),
    };
    sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('debugMode', 'false');
    this.authenticationService.setUser(user);
    this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
}
