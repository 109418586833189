<div class="content-container" id="nettingAction">
    <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">
            <span class="content-span full-width">Netting {{data.dReference}} </span>
        </div>
        <br>
        <div class="row">
        <span class="content-span-small">Veuillez sélectionner le type de projet pour le netting : </span>
        </div>
        <mat-form-field  appearance="outline" color="accent" >
             <mat-select formControlName="nettingsNames"  >
              <mat-option  *ngFor="let name of nettingsNames" [value]="name">
                {{name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button style="margin-left: 10px; z-index: 7;" color="warn" type="reset" (click)="closeDialog()" > Annuler </button>
          <button mat-raised-button style="margin-left: 10px;" class="thirdrow blue" [style.background-color]="theme.getColor('Main Light')" type="submit" [disabled]="formGroup.value.nettingsNames === ''" > Valider </button>

          <input hidden matInput required formControlName="ndemand" type="text" >


    </form>
</div>
