<div class="menu-box">
  <a class="box" href="core/dashboard/alerts" [style.background-color]="theme.getColor('Main Dark')">
    <div class="box-icon">
      <mat-icon>notifications</mat-icon>
    </div>
    <div class="box-label">Suivi des Alertes</div>
  </a>

  <a class="box" href="core/dashboard/orders" [style.background-color]="theme.getColor('Main Dark')">
    <div class="box-icon">
      <mat-icon>dvr</mat-icon>
    </div>
    <div class="box-label">Suivi de Commandes</div>
  </a>

  <a class="box" href="core/dashboard/apps" [style.background-color]="theme.getColor('Main Dark')">
    <div class="box-icon">
      <mat-icon>train</mat-icon>
    </div>
    <div class="box-label">Suivi d'Applications</div>
  </a>

  <a class="box" href="/core/facturation/facturation-vue_netting" [style.background-color]="theme.getColor('Main Dark')"
     [ngStyle]="config.getConfiguration().experimentalEnable === 'false' ? {'display':'none'}:{}">
    <div class="box-icon">
      <mat-icon>receipt</mat-icon>
    </div>
    <div class="box-label">Suivi des Nettings</div>
  </a>

  <a class="box" href="/core/dashboard/powerbi" [style.background-color]="theme.getColor('Main Dark')"
     [ngStyle]="config.getConfiguration().experimentalEnable === 'false' ? {'display':'none'}:{}">
    <div class="box-icon">
      <mat-icon>analytics</mat-icon>
    </div>
    <div class="box-label">Rapport Power BI</div>
  </a>
</div>
