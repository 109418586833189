import {Component, Inject, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogFormNettingService} from "src/app/shared/dialog-form-netting/dialog-form-netting.service";
import {INetting} from "src/app/shared/models/netting-account";
import {INettingPack} from "src/app/shared/models/netting-pack";
import {ThemeService} from "src/app/shared/services/theme.service";
import {FacturationService} from "../services/facturation.service";

@Component({
  selector: 'app-dialog-facturation-netting',
  templateUrl: './dialog-facturation-netting.component.html',
  styleUrls: ['./dialog-facturation-netting.component.less']
})
export class DialogFacturationNettingComponent {
  public formGroup!: UntypedFormGroup;
  nettingsAccounts: INetting[] = [];
  nettingsNames: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _fb: UntypedFormBuilder, public dialogRef: MatDialogRef<number>,
              private facturationService: FacturationService,
              public theme: ThemeService,
              private _snackBar: MatSnackBar) {

    this.formGroup = this._fb.group({
      ndemand: '',
      nettingsNames: ''
    });

    this.formGroup.setValue({ndemand: '', nettingsNames: ''});

    //Creates nettingAccountsList
    this.nettingsAccounts = [];
    this.nettingsNames.push("aucun");

    this.facturationService.getNettingAccountsFromDemandNumber(this.data.dNumber).subscribe({
      next: value => {
        this.nettingsAccounts = value;
        this.nettingsAccounts.forEach((n: INetting) => {
          this.nettingsNames.push(n.name + "");
        });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });

  }

  onSubmit() {
    if (this.nettingsAccounts.length == 0 || this.formGroup.value.nettingsNames == "aucun") {
      this.formGroup.value.nettingsNames = null;
      this.formGroup.value.ndemand = this.data.dNumber;
    } else {
      const name = this.formGroup.value.nettingsNames;
      let res = this.nettingsAccounts[0];

      this.nettingsAccounts.forEach((n: INetting) => {
        if (name == n.name) {
          res = n;
        }
      })

      this.formGroup.value.nettingsNames = res;
      this.formGroup.value.ndemand = this.data.dNumber;
    }

    this.facturationService.createNettingPack(this.formGroup.value).subscribe({
      next: data => {
        this.close();
      },
      error: err => {
        console.log(err);
        this.openSnackBar(err);
      },
      complete: () => {
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(this.formGroup.value);
  }

  openSnackBar(err: string) {
    this._snackBar.open('ERREUR', err, {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
