import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import * as fileSaver from "file-saver";
import {IInvoiceDemands, IInvoiceOrderSummary} from "src/app/shared/models/invoices";
import {ThemeService} from "src/app/shared/services/theme.service";
import {ApplicationSettingsService} from "../../application-settings/application-settings.service";
import {DialogFacturationNettingComponent} from "../facturation-netting-dialog/dialog-facturation-netting.component";
import {FacturationService} from "../services/facturation.service";

@Component({
  selector: 'app-facturation-list',
  templateUrl: './facturation-list.component.html',
  styleUrls: ['./facturation-list.component.less']
})

export class FacturationListComponent {
  public dataSummary!: MatTableDataSource<IInvoiceOrderSummary>;
  public dataImported!: MatTableDataSource<IInvoiceDemands>;

  public displayedColumns: string[] = ["actions", "demandNumber", "demandInvoice"];
  public displayedColumnsData: string[] = ["demandId", "demandNumber", "demandCost", "demandDescription", "demandApp", "demandService", "demandType", "demandInvoice", "netting", "actions"];

  public currentYearPack: number;
  public yearSelected!: number;
  public years: number[] = [];

  public currentMonth!: number;
  public monthSelected!: string;
  public months: string[] = [];

  public monthNames: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"];


  public canExport = true;
  public totalFacture = 0;

  public demandsExcluded: string[] = [];

  public spinner = false;

  constructor(private applicationSettingsService: ApplicationSettingsService,
              private facturationService: FacturationService,
              private changeDetectorRefs: ChangeDetectorRef,
              public theme: ThemeService,
              private dialog: MatDialog) {

    this.currentYearPack = applicationSettingsService.getApplicationSettings().Pack_current_year;
    for (let i = (this.currentYearPack - 1); i < (this.currentYearPack + 1); i++) {
      this.years.push(i);
    }

    this.yearSelected = this.currentYearPack;

    facturationService.getLastClosedMonth().subscribe({
      next: data => {
        this.currentMonth = data;
        this.monthSelected = this.monthNames[this.currentMonth - 1] + " - " + this.yearSelected;
        for (let i = 0; i < 12; i++) {
          this.months.push(this.monthNames[i] + " - " + this.yearSelected);
        }

        if (this.monthSelected && this.yearSelected) {
          this.refresh(this.yearSelected, this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1);
        }
      },
      error: err => {
        console.log(err);
        this.currentMonth = 1;
      },
      complete: () => {
      }
    });

  }

  changeYear(year: number): void {
    this.spinner = true;
    this.yearSelected = year;
    this.months.splice(0, this.months.length)
    if (this.yearSelected == year) {
      for (let i = 0; i < 12; i++) {
        this.months.push(this.monthNames[i] + " - " + this.yearSelected);
      }

      if (this.months.length == 12) {
        this.monthSelected = this.monthNames[this.currentMonth - 1] + " - " + this.yearSelected;
        this.refresh(this.yearSelected, this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1);
      }
      this.spinner = false;
      this.changeDetectorRefs.detectChanges();
    }
  }

  changeMonth(month: string): void {
    this.spinner = true;
    this.monthSelected = month;

    if (this.monthSelected == month) {
      this.currentMonth = this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1;
      this.refresh(this.yearSelected, this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1);
      this.spinner = false;
      this.changeDetectorRefs.detectChanges();
    }
  }

  isRetarded(element: any): boolean {
    return element.echeanceRetarded;
  }

  isExceeded(element: any): boolean {
    return element.demandCostExceeded;
  }

  exportPreparationNetting(year: number, month: number, excludedList: string[]): void {
    this.spinner = true;
    this.facturationService.exportPreparationNetting(year, month, excludedList).subscribe({
      next: data => {
        const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, "Mise en Facturation–BDC " + this.monthNames[month - 1] + "-"
          + year + " - " + this.applicationSettingsService.applicationSettings.CDS_name
          + " - " + new Date().toLocaleDateString());
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.spinner = false;
      }
    })
  }

  refresh(year: number, month: number) {
    this.facturationService.getDemandInvoice(year, month).subscribe({
      next: data => {
        this.dataImported = data;
        this.dataImported.data = data;

        this.dataImported.data = this.dataImported.data.sort((a, b) => {
          if (a.demandReference > b.demandReference) return 1;
          if (a.demandReference < b.demandReference) return -1;
          return 0;
        });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {

      }
    });

    this.facturationService.getDemandInvoiceSummary(year, month).subscribe({
      next: data => {
        this.dataSummary = data;
        this.dataSummary.data = data;

        this.dataSummary.data = this.dataSummary.data.sort((a, b) => {
          if (a.demandReference > b.demandReference) return 1;
          if (a.demandReference < b.demandReference) return -1;
          return 0;
        });

        //Total à facturer, i.e. la somme des totaux des commandes non exclues
        this.totalFacture = data.reduce((sum: number, current: IInvoiceOrderSummary) => sum + current.demandAmount, 0);
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  exculdeDemand(ref: string) {
    const index = this.demandsExcluded.indexOf(ref);
    const elements = document.getElementsByClassName(ref) as HTMLCollectionOf<HTMLElement>;
    const totalToRemove = this.dataSummary.data.find(x => x.demandReference === ref);
    if (index > -1) {
      this.demandsExcluded.splice(index, 1);
      Array.prototype.forEach.call(elements, function (el) {
        el.style.textDecoration = "none";
      });

      if (totalToRemove !== undefined) {
        this.totalFacture = this.totalFacture + totalToRemove.demandAmount;
      }
    } else {
      Array.prototype.forEach.call(elements, function (el) {
        el.style.textDecoration = "line-through";
      });
      this.demandsExcluded.push(ref);

      if (totalToRemove !== undefined) {
        this.totalFacture = this.totalFacture - totalToRemove.demandAmount;
      }
    }

  }

  dialogChangeNetting(dNum: string, dRef: string) {
    const dialogRef = this.dialog.open(DialogFacturationNettingComponent, {
      width: '600px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {dNumber: dNum, dReference: dRef}
    });
    dialogRef.afterClosed().subscribe({
      next: value => {
        this.facturationService.getNettingFromDemand(value.ndemand).subscribe({
          next: res => {
            const elements = document.getElementById(value.ndemand) as HTMLElement;
            if (res == null) elements.textContent = "Aucun";
            else elements.textContent = res.name + "";
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });

  }
}
