<div class="card">
  <app-facturation-netting_regularisation
    (emitYearSelected)="updateYearSelected($event)"
    (emitMonthSelected)="updateMonthSelected($event)"
    (emitVueSelected)="updateVueSelected($event)"
    (emitNetRegisShown)="updateNetRegisShown($event)"
    [isFormDisabled]="isFormDisabled">
  </app-facturation-netting_regularisation>

  <div *ngIf="NetRegisShown">

    <div id="pageSection-leftSection" class="column">
      <div class="tableCA">
        <mat-table class="light mat-elevation-z4" [dataSource]="CA_facture" matSort>
          <ng-container matColumnDef="CA_facture">
            <th mat-header-cell *matHeaderCellDef> CA facturé</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field>
                <input matInput class="input" name="CA" type="number" [(ngModel)]="element.montant"
                       [readonly]="tableReadOnly" (change)="changeCA(element.montant)" ngDefaultControl>
                <span matSuffix>€</span>
              </mat-form-field>
            </td>
          </ng-container>
          ->
          <tr mat-header-row *matHeaderRowDef="displayedColumns_CA ; sticky: true;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_CA; "></tr>
        </mat-table>
      </div>

      <fieldset class="Ecart bloc" id="ecart">
        <legend>Ecart</legend>
        <label> {{ ecart }} €</label>
      </fieldset>
      <fieldset class="Schema_manquant bloc" *ngIf="displaySchemaManquant">
        <legend class="displayNone">schema manquant</legend>
        <label> Schéma de netting manquant</label>
      </fieldset>
    </div>

    <div id="pageSection-centerSection" class="column">
      <mat-table class="light mat-elevation-z4" [dataSource]="nettingSource" matSort>
        <ng-container matColumnDef="code_netting">
          <th mat-header-cell *matHeaderCellDef> Code netting</th>
          <td mat-cell *matCellDef="let element" [class.Ligne_rouge]="LineIsRed(element.schema_id)"> {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="montant">
          <th mat-header-cell *matHeaderCellDef> Montant</th>
          <td mat-cell *matCellDef="let element" [class.Ligne_rouge]="LineIsRed(element.schema_id)">
            <mat-form-field>
              <input matInput class="input" name="montant" type="number" [(ngModel)]="element.montant"
                     [readonly]="tableReadOnly" (change)="changeMontant(element)" ngDefaultControl>
              <span matSuffix>€</span>
            </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns_netting ; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns_netting; "></tr>
      </mat-table>
    </div>

    <div id="pageSection-rightSection" class="column">
      <fieldset class="Sequence bloc" id="sequence">
        <legend>Sequence départ</legend>
        <input type="number" required min="1" [(ngModel)]="sequenceSelected" (ngModelChange)="changeSequence($event)"
               [disabled]="sequenceDisabled" name="sequence">
      </fieldset>

      <button mat-raised-button class="indigo input bloc" [style.background-color]="theme.getColor('Main Dark')"
              type="submit" id="generer_netting" [disabled]="isGenererDisabled" (click)="genererService()"> Générer
        Netting
      </button>
      <button mat-raised-button class="indigo input bloc" [style.background-color]="theme.getColor('Main Dark')"
              type="submit" id="abandon" (click)="abandonService()"> Abandon
      </button>
    </div>
  </div>


  <div *ngIf="NetGenisShown">
    <div>
      <button mat-raised-button class="indigo input bloc" [style.background-color]="theme.getColor('Main Dark')"
              type="submit" id="export" (click)="exportService()">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
      <button mat-raised-button class="indigo input bloc right" [style.background-color]="theme.getColor('Main Dark')"
              type="submit" id="retour" (click)="retourService()"> Retour
      </button>
    </div>

    <div>
      <mat-table class="light mat-elevation-z4" [dataSource]="nettingCalculatedSource" matSort>
        <ng-container *ngFor="let dispCol of displayedColumnsGen" matColumnDef="{{dispCol.key}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ dispCol.header }}</th>
          <td mat-cell *matCellDef="let element"
              [class.Cell_rouge]="CellIsRed(element[dispCol.key], dispCol.header)">{{ element[dispCol.key] }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsGenKeys;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsGenKeys"></tr>
      </mat-table>
    </div>
  </div>

</div>
