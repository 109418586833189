import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../application-settings.service';
import { DirectoryService } from '../repository-manager/directory.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.less']
})
export class FileManagerComponent {

  userGuide!: File;
  dsiGuide!: File;
  dataGuide!: File;
  commandGuide!: File;
  alertGuide!: File;
  adminGuide!: File;
  appsGuide!: File;
  conf!: File;

  debugMode = false;

  constructor(public ds: DirectoryService, private _snackBar: MatSnackBar,
    private as: ApplicationSettingsService
  ) {
    const debugMode = localStorage.getItem('debugMode');
    if (debugMode !== null) {
      this.debugMode = JSON.parse(debugMode);
    }
  }

  importFile(file: string) {
    let f: File;
    switch (file) {
      case "userGuide":
        f = this.userGuide;
        break;
      case "dsiGuide":
        f = this.dsiGuide;
        break;
      case "dataGuide":
        f = this.dataGuide;
        break;
      case "commandGuide":
        f = this.commandGuide;
        break;
      case "alertGuide":
        f = this.alertGuide;
        break;
      case "adminGuide":
        f = this.adminGuide;
        break;
      case "appsGuide":
        f = this.appsGuide;
        break;
      case "conf":
        f = this.conf;
        break;
      default:
        return;
    }
    if (f != null) {
      if (file == "conf") this.ds.importConf(f).subscribe({
        next: res => {
          this.openSuccessSnackBar("Le fichier s'est correctement importé.");
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      })
        ;
      else
        this.ds.importFile(f, file).subscribe({
          next: res => {
            this.openSuccessSnackBar("Le fichier s'est correctement importé.");
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
    }

  }

  downloadFile(name: string) {
    this.ds.readFileContents(name + ".conf").subscribe(data => {
      const blob: any = new Blob(["\ufeff", data], { type: 'text;charset=utf-8;' });
      window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, name + this.as.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-') + ".conf");
    })
  }

  openSuccessSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 10000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snack-bar'
    });
  }

  setDebugMode(value: boolean) {
    this.debugMode = value;
    localStorage.setItem('debugMode', value.toString());
    // refresh de la page
    window.location.reload();
  }
}
