<mat-spinner *ngIf="spinner"></mat-spinner>
<div class="imported-invoices" [ngClass]="{'spinner': spinner}">
  <div class="section-container" id="leftSection-nav">
    <mat-table class="light mat-elevation-z4 left" [dataSource]="dataSummary">

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="exclure"> Exclure</th>
        <td mat-cell *matCellDef="let element" class="exclure">
          <mat-checkbox
            (change)="exculdeDemand(element.demandReference)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- orderId Column -->
      <ng-container matColumnDef="demandNumber">
        <th mat-header-cell *matHeaderCellDef > Commande</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandReference }}</td>
      </ng-container>

      <!-- totalInvoice Column -->
      <ng-container matColumnDef="demandInvoice">
        <th mat-header-cell *matHeaderCellDef> A facturer</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandAmount | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; "
          class="row focusable"
          [ngClass]="{'red': isExceeded(row)}"></tr>

    </mat-table>
  </div>
  <div class="section-container" id="rightSection-nav">
    <div class="table_header">
      <div class="facturationHeader">
        <mat-form-field appearance="outline">
          <mat-label>Mois de facturation</mat-label>
          <mat-select  [(ngModel)]="monthSelected" (selectionChange)="changeMonth($event.value)">
            <mat-option></mat-option>
            <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="facturationHeader">
        <mat-form-field appearance="outline">
          <mat-label>Année PACK</mat-label>
          <mat-select  [(ngModel)]="yearSelected" (selectionChange)="changeYear($event.value)">
            <mat-option></mat-option>
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="facturationHeader" id="total_invoices">
        <div class="lb_name_size">Total à facturer : {{ totalFacture | number:'1.1-2':"fr-FR" }} €</div>
      </div>

      <div class="facturationHeader large" id="exportNetting">
        <button mat-raised-button class="indigo input large-button"
                [style.background-color]="theme.getColor('Main Dark')" type="submit"
                (click)="exportPreparationNetting(yearSelected, currentMonth, demandsExcluded)">Valider la facturation
        </button>
      </div>
    </div>

    <mat-table class="light mat-elevation-z4 right" [dataSource]="dataImported">
      <ng-container matColumnDef="demandId">
        <th mat-header-cell *matHeaderCellDef class="ref"> Référence de la commande</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandReference }}</td>
      </ng-container>

      <ng-container matColumnDef="demandNumber">
        <th mat-header-cell *matHeaderCellDef> N° demande</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="demandCost">
        <th mat-header-cell *matHeaderCellDef> Montant demande avant réduction</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandCost }}</td>
      </ng-container>

      <ng-container matColumnDef="demandDescription">
        <th mat-header-cell *matHeaderCellDef> Désignation</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandDescription }}</td>
      </ng-container>

      <ng-container matColumnDef="demandApp">
        <th mat-header-cell *matHeaderCellDef> Application</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandApplicationName }}</td>
      </ng-container>

      <ng-container matColumnDef="demandService">
        <th mat-header-cell *matHeaderCellDef> Service appelé</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandServiceType }}</td>
      </ng-container>

      <ng-container matColumnDef="demandType">
        <th mat-header-cell *matHeaderCellDef> Type de prestation</th>
        <td mat-cell *matCellDef="let element"> {{ element.demandPrestationType }}</td>
      </ng-container>

      <ng-container matColumnDef="demandInvoice">
        <th mat-header-cell *matHeaderCellDef> A facturer</th>
        <td mat-cell *matCellDef="let element"> {{ element.echeanceCost | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="netting">
        <th mat-header-cell *matHeaderCellDef> Netting</th>
        <td mat-cell *matCellDef="let element" id="{{element.demandNumber}}"> {{ element.demandNetting }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <button mat-icon-button aria-label="edit"
                    (click)="dialogChangeNetting(element.demandNumber, element.demandReference)">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsData ; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsData;"
          class="striked {{row.demandReference}} focusable"
          [ngClass]="{'red': isRetarded(row)}"></tr>

    </mat-table>
  </div>
</div>
